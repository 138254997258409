import React, { Component } from 'react'

export default class Form extends Component {
  state = {
    sujet: this.props.location
      ? this.props.location.state
        ? this.props.location.state.sujet
          ? this.props.location.state.sujet
          : 'Inforation générale'
        : 'Inforation générale'
      : 'Inforation générale',
    product: this.props.location
      ? this.props.location.state
        ? this.props.location.state.product
          ? this.props.location.state.product
          : ''
        : ''
      : '',
    about: false,
  }

  sujetChange(e) {
    this.setState({ sujet: e.target.value })
  }
  productChange(e) {
    this.setState({ product: e.target.value })
  }
  render() {
    return (
      <form name='contact' method='POST' data-netlify='true' action='/success'>
        <input
          type='hidden'
          name='form-name'
          value='contact'
          aria-label='contact'
        />
        <div className='fieldset'>
          <h2 className='blockSubTitle'>Coordonées</h2>
          <p>
            <label htmlFor='name'>
              Nom<sup className='required'>*</sup> :
              <input type='text' name='name' aria-label='name' required />
            </label>
          </p>
          <p>
            <label htmlFor='mail'>
              Mail<sup className='required'>*</sup> :
              <input type='mail' name='mail' aria-label='mail' required />
            </label>
          </p>
          <p>
            <label htmlFor='phone'>
              Téléphone<sup className='required'>*</sup> :
              <input type='text' name='phone' aria-label='phone' required />
            </label>
          </p>
        </div>
        <div className='fieldset'>
          <h2 className='blockSubTitle'>Objet</h2>
          <p>
            <label>
              Sujet de votre message
              <select
                name='Sujet'
                value={this.state.sujet}
                onChange={this.sujetChange.bind(this)}
                onBlur={this.sujetChange.bind(this)}
              >
                <option value='Information générale'>
                  Demande d'information : générale
                </option>
                <option value='Information produits'>
                  Demande d'information : produits
                </option>
                <option value='autre'>Autre</option>
              </select>
            </label>
          </p>
          <p
            style={{
              display:
                this.state.sujet === 'Information produits' ? 'block' : 'none',
            }}
          >
            <label>
              Produits
              <select
                name='Produit'
                value={this.state.product}
                onChange={this.productChange.bind(this)}
                onBlur={this.productChange.bind(this)}
              >
                <option value=''>---</option>
                {this.props.product.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={item.title}>{item.title}</option>
                      {item.colorVar
                        ? item.colorVar.map((itm, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                <option value={itm.title}>
                                  • {item.title} {itm.title}
                                </option>

                                {item.produits.map((it, id) => {
                                  return (
                                    <React.Fragment key={id}>
                                      <option value={it.title}>
                                        - {it.title}
                                      </option>
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })
                        : ''}
                    </React.Fragment>
                  )
                })}
              </select>
            </label>
          </p>
        </div>
        <div className='fieldset'>
          <h2 className='blockSubTitle'>Message</h2>

          <p>
            <label htmlFor='msg'>
              Message<sup className='required'>*</sup> :
              <textarea aria-label='msg' name='message' required></textarea>
            </label>
          </p>
        </div>
        <div>
          <button type='submit' className='button'>
            Envoyer
          </button>
        </div>
      </form>
    )
  }
}
