import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//component
import Seo from '../components/SEO'
import Form from '../components/block/Form'
//scss
import '../scss/page/contact.scss'
//data
const getData = graphql`
  {
    c: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        title
        colorVar {
          title
        }
        produits {
          title
          color {
            title
          }
        }
      }
    }
  }
`
const TemplateContact = ({ pageContext, location }) => {
  const data = useStaticQuery(getData)
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />
      <div className='contact'>
        <div className='container'>
          <Form location={location} product={data.c.nodes} />
        </div>
      </div>
    </>
  )
}

export default TemplateContact
